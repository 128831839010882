/* storybook-check-ignore */
import React from 'react';

import { Box, Flex, H1, Image, Link, Text } from '@opendoor/bricks/core';

const HomeHero: React.FC = () => {
  return (
    <Box
      mx="auto"
      maxWidth="var(--content-max-width)"
      py={['1.5rem', '1rem', '2rem']}
      px={['1.5rem', '1.5rem', '2rem']}
    >
      <Flex
        alignItems="start"
        justifyContent="space-between"
        flexDirection={['column', 'column', 'row']}
      >
        <Flex
          data-aos="fade-up"
          flexDirection="column"
          justifyContent="center"
          mr={[0, 0, '2rem', 140, '12%']}
          mt={[0, 0, '1.5rem', '1.5rem', '3.5rem']}
        >
          <H1
            fontWeight="semibold"
            fontSize={['s4', 's4', 's5', 's5', 's6']}
            lineHeight={['s4', 's4', 's5', 's5', 's6']}
          >
            Off-market <br />
            homes you can't find&nbsp;
            <Text
              as="span"
              fontFamily="serif"
              fontStyle="italic"
              // @ts-expect-error letter-spacing is now tokenized in bricks
              letterSpacing="-0.03em"
              color="brand50"
              display={['inherit', 'inherit', 'inline']}
            >
              anywhere else
            </Text>
          </H1>

          <Text
            mt="1rem"
            lineHeight={['s0', 's0', 's0', 's0', 's2']}
            color="neutrals90"
            fontSize={['s0', 's0', 's0', 's0', 's2']}
          >
            Skip the bidding wars. Self-tour and buy off-market homes at exclusive prices. We even
            offer an Appraisal Price Match Guarantee.
          </Text>

          <Link
            variant="primary-button"
            size="lg"
            fontSize="s2"
            lineHeight="s2"
            minW="210px"
            maxW={[238, 238, 240, 270]}
            aria-label=""
            href="/exclusives/gallery"
            mt={['2rem', '2rem', '2rem', '2rem', '2.5rem']}
            analyticsName="cosmos-exclusives-home-hero-see-all-homes"
          >
            See all homes
          </Link>
          <Text
            mt="1rem"
            mb="2.5rem"
            fontSize="s0"
            lineHeight="s0"
            textAlign="left"
            fontWeight="semibold"
          >
            Currently only available in Austin, Houston, and Dallas-Fort Worth, TX
          </Text>
        </Flex>
        <Image
          maxW={670}
          data-aos="fade-up"
          data-aos-delay="350"
          w={['100%', '100%', '45%', '45%', '50%']}
          alt="Various styles of Opendoor exclusive homes. New homes get dropped daily and are exclusive to Opendoor for 14 days."
          src="https://images.opendoor.com/source/s3/imgdrop-production/70f0b08288c74c2294031207057d0a8a.png?preset=square-2048&auto=compress,format&fit=max&w=580"
        />
      </Flex>
    </Box>
  );
};

export default HomeHero;
